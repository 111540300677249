import { Box, Heading, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import LeaderboardTable, { LeaderboardItemProps } from "../components/Tables/LeaderboardTable";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ErrorToast from "../components/Toasts/Error";
import AuthContext from "../components/context/AuthContext";
import { darken } from "../utils/colors";
import { API_HOST } from "../config";

export default function Leaderboard() {
    const toast = useToast();
    const [items, setItems] = useState<LeaderboardItemProps[]>([]);
    const [you, setYou] = useState<LeaderboardItemProps>();
    const [loading, setLoading] = useState<boolean>(true);
    const { isLoading, isAuthenticated, user } = useContext(AuthContext);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(`${API_HOST}/api/tasks/leaderboard${user?.address ? `?address=${user?.address}` : ''}`);
                if (data.status === 'success') {
                    setItems(data.data.board);

                    if (data.data.you) {
                        setYou({ ...data.data.you });
                    }
                    setLoading(false);
                }
                else {
                    throw new Error('Something went wrong!');
                }
            } catch (error) {
                toast({
                    position: 'bottom-right',
                    render: () => <ErrorToast text="Something Went Wrong :(" description="Please try again later in a few minutes" />,
                    duration: 4000
                })
            }
        })();
    }, [user]);
    return (
        <Stack w={'full'} spacing={'2rem'}>
            <Stack direction={{ base: 'column', sm: 'column', md: 'column', lg: 'row' }} spacing={{ base: 4, sm: 4, md: 4, lg: 2 }} justify={'space-between'} align={'center'}>
                {
                    you?.points && (
                        <Skeleton isLoaded={!isLoading && !loading} borderRadius={'10px'} startColor={'#FF047E'} endColor={darken('#FF047E', 30)}>
                            <Box
                                w={'224px'}
                                bg={'#FF047E'}
                                textAlign={'center'}
                                borderRadius={'10px'}
                                p={'20px 30px 20px 30px'}
                            >
                                <Text>Your points: {you.points}</Text>
                            </Box>
                        </Skeleton>
                    )
                }
                <Heading fontWeight={500} fontSize={'xl'} fontFamily={'Space Grotesk'}>Leaderboard</Heading>
                {
                    you?.rank && (
                        <Skeleton isLoaded={!isLoading} borderRadius={'10px'} startColor={'#FF047E'} endColor={darken('#FF047E', 30)}>
                            <Box
                                w={'224px'}
                                bg={'#FF047E'}
                                textAlign={'center'}
                                borderRadius={'10px'}
                                p={'20px 30px 20px 30px'}
                            >
                                <Text>Your Rank: {you.rank}</Text>
                            </Box>
                        </Skeleton>
                    )
                }
            </Stack>
            <Stack>
                <Text ml={4} fontStyle={'italic'} fontSize={'14px'} color={'gray.500'}>Points may take 15-30 minutes to update sometimes on the leaderboard.</Text>
                <LeaderboardTable
                    loading={loading}
                    users={items}
                    you={you}
                />
            </Stack>
        </Stack >
    )
}