import { Avatar, AvatarProps, IconButton } from "@chakra-ui/react";

export interface WagmiAvatarProps extends AvatarProps {
    address: `0x${string}`,
    click?: Function
}
export default function WagmiAvatar({ address, click, ...rest }: WagmiAvatarProps) {
    return (
        <Avatar
            as={IconButton}
            size={'md'}
            {...rest}
            src={`https://effigy.im/a/${address}.svg`}
            click={() => click && click()}
        />
    )
}