import { Heading, Icon, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLink } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function AddLiquidity() {
    const toast = useToast();
    const { user } = useContext(AuthContext);

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Add Liquidty on Zayn Dex Pools</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are invited to contribute liquidity to the Zayn Decentralized Exchange (DEX) by adding liquidity pairs. By providing liquidity, users enhance the efficiency and depth of the exchange, enabling smoother transactions and reducing slippage for traders. Each successful liquidity addition on the Zayn DEX earns participants 40 🪙, recognizing their pivotal role in bolstering the liquidity pool and fostering a vibrant decentralized financial ecosystem. Thank you for your valuable contribution to the growth and sustainability of our network!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                <Stack direction={'row'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                    <Text fontWeight={700} textAlign={'start'}>Add Liquidty:</Text>
                    <Text as={Link} href={'https://www.zaynswap.com/add/ETH'} target={'_blank'} bg={'black'} borderRadius={'15px'} p={4} cursor={'pointer'}>https://www.zaynswap.com/add/ETH</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}