import { Route, Routes } from "react-router-dom";
import PageRoutes from "./PageRoutes";

export default function PageRouter() {
    return (
        <Routes>
            {
                PageRoutes.map(route => (
                    <Route path={route.url} element={route.element} />
                ))
            }
        </Routes>
    )
}