import { Heading, Icon, Image, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import axios from "axios";
import ErrorToast from "../Toasts/Error";
import SuccessToast from "../Toasts/Success";
import { API_HOST } from "../../config";

export default function Follow() {
    const toast = useToast();
    const [isTaskDone, setTaskDone] = useState<boolean>();
    const { isLoading, user } = useContext(AuthContext);
    const [isFollowing, setFollowing] = useState<boolean>(false);

    async function checkTaskDone(action: string) {
        try {
            const { data } = await axios.get(`${API_HOST}/api/tasks/twitter/has/${action}?token=${user?.token}`);
            if (data.status === 'success') {
                setTaskDone(true);
            } else {
                setTaskDone(false);
            }
        } catch (error) {
            toast({
                position: 'bottom-right',
                render: () => <ErrorToast text="Something Went Wrong :(" description="The page will reload in 5 seconds please try again later." />,
                duration: 4000
            });
            setTimeout(() => window.location.reload(), 5000);
        }
    }

    useEffect(() => {
        if (user?.token) {
            checkTaskDone('follow');
        }
    }, [user]);

    function onConnectClick() {
        if (user?.token) {
            window.location.href = `${API_HOST}/api/auth/twitter?token=${user.token}&callback=${window.location.href}`;
        } else {
            toast({
                position: 'bottom-right',
                render: () => <ErrorToast text="Connect your wallet first!" />,
                duration: 4000
            });
        }
    }

    async function onFollowClick() {
        setFollowing(true);
        if (user?.token) {
            const { data } = await axios.post(`${API_HOST}/api/tasks/twitter/follow`, {}, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            });

            if (data.status !== 'success') {
                toast({
                    position: 'bottom-right',
                    render: () => <ErrorToast text="Something went Wrong :(" description="Page will reload in 5 seconds, Please try again." />,
                    duration: 4000
                });
                setTimeout(() => window.location.reload(), 5000);
            }

            toast({
                position: 'bottom-right',
                render: () => <SuccessToast text="Task Completed! You've been rewarded 40 🪙" description="Complete other tasks to earn more points." />,
                duration: 7000
            });

            checkTaskDone('follow');
        } else {
            toast({
                position: 'bottom-right',
                render: () => <ErrorToast text="Connect your wallet first!" />,
                duration: 4000
            });
        }
        setFollowing(false);
    }

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Follow Zayn on X.com</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>To successfully complete this task, please ensure you follow Zayn Network on Twitter. This will keep you updated with the latest news and activities from Zayn Network, helping us create a vibrant community connected through shared interests and information.</Text>
            </Stack>

            <Stack w={'full'} spacing={0} mt={8}>
                {
                    isLoading ? (
                        <Skeleton w={'214.8px'} h={'48px'} />
                    )
                        :
                        (
                            user === undefined ? (
                                <Text>Connect Wallet to continue...</Text>
                            ) :
                                (
                                    isTaskDone === undefined ? (
                                        <Skeleton w={'214.8px'} h={'48px'} />
                                    )
                                        : (
                                            isTaskDone ? (
                                                <Stack direction={'row'} spacing={3} align={'center'}>
                                                    <Stack
                                                        flex={0}
                                                    >
                                                        <Icon as={IoMdCheckmarkCircleOutline} w={'25px'} h={'25px'} color={'#2a7e1b'} />
                                                    </Stack>
                                                    <Stack
                                                        flex={1}
                                                    >
                                                        <Text>Task Completed</Text>
                                                    </Stack>
                                                </Stack>
                                            )
                                                :
                                                (
                                                    (
                                                        user.twitter_id !== null ? (
                                                            <XButton text={'Follow Zayn on'} onClick={onFollowClick} isLoading={isFollowing} />
                                                        )
                                                            :
                                                            (
                                                                <XButton text={'Connect to'} onClick={onConnectClick} />
                                                            )
                                                    )
                                                )
                                        )
                                )
                        )

                }
            </Stack>
        </Stack>
    )
}