import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Stack, Text, Input } from "@chakra-ui/react";
import NormalButton from "../Buttons/NormalButton";
import AirdropABI from '../../abis/airdrop.json';
// import getSigner from '../../signer/getSigner';
import { useAccount } from 'wagmi';
import { base } from 'wagmi/chains';
import { useEthersSigner } from '../../signer/ethersAdapter';
import ConnectButton from "../Buttons/ConnectButton";
import SwitchChainButton from '../Buttons/SwitchChainButton'

const AirdropAddress = '0x0E84079dE55C6F12eddB84cF3654260306dB4270';

interface AirdropData {
    address: string;
    amount: string;
    proof: string[];
}

function InfoModal() {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { address, isConnected, chain } = useAccount();
    const [amount, setAmount] = useState<string>('');
    const [amounT, setAmounT] = useState<string>('');
    const [proof, setProof] = useState<string[]>([]);
    const [isClaimed, setIsClaimed] = useState<boolean | null>(null);
    const [message, setMessage] = useState<string>('');
    const [airdropData, setAirdropData] = useState<AirdropData[]>([]);
    const [isEligible, setIsEligible] = useState<boolean>(false);
    const signer = useEthersSigner();

    useEffect(() => {
        // Fetch airdrop data from JSON file
        const fetchAirdropData = async () => {
            try {
                const response = await fetch('/abis/usdtProofs.json');
                const data: AirdropData[] = await response.json();
                setAirdropData(data);
            } catch (error) {
                console.error('Failed to fetch airdrop data:', error);
            }
        };

        fetchAirdropData();
    }, []);

    useEffect(() => {
        // Fetch data from JSON based on connected address
        if (address && airdropData.length > 0) {
            const userData = airdropData.find((data) => data.address.toLowerCase() === address.toLowerCase());
            if (userData) {
                setAmount(ethers.utils.formatUnits(userData.amount, 6));
                setAmounT(userData.amount);
                setProof(userData.proof);
                setIsEligible(true);
            } else {
                setAmount('0')
                setIsEligible(false);
            }
        }
    }, [address, airdropData]);

    const checkIsClaimed = async () => {
        if (!signer) {
            // setMessage('Signer not initialized.');
            return;
        }

        try {
            const airdrop = new ethers.Contract(AirdropAddress, AirdropABI, signer);
            const claimed = await airdrop.isClaimed(address);
            setIsClaimed(claimed);
            // setMessage(claimed ? 'Airdrop already claimed.' : 'You can claim your airdrop.');
        } catch (error) {
            console.error('Failed to check if claimed:', error);
            // setMessage('Failed to check if claimed');
        }
    };

    useEffect(() => {
        if (isOpen && isEligible) {
            checkIsClaimed();
        }
    }, [isOpen, isEligible]);

    const claimTokens = async () => {
        if (!signer) {
            // setMessage('Signer not initialized.');
            return;
        }

        try {
            const airdrop = new ethers.Contract(AirdropAddress, AirdropABI, signer);
            const txResponse = await airdrop.claim(address, amounT, proof);
            await txResponse.wait(); // Wait for the transaction to be mined
            setMessage(`Claim successful: ${txResponse.hash}`);
            setIsClaimed(true);
        } catch (error) {
            console.error('Claim failed:', error);
            setMessage('Claim failed');
        }
    };

    const isClaimDisabled = !isConnected || chain !== base || !isEligible || isClaimed === true;

    return (
        <>
            <NormalButton buttonColor="#313743" onClick={onOpen}>{`Claim Tokens`}</NormalButton>

            <Modal isOpen={isOpen} onClose={onClose} isCentered >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{`Claim Tokens`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Stack spacing={4}>
                            <Stack spacing={0}>
                                <Text>{`Address:`}</Text>
                                <Input
                                    type="text"
                                    value={address}
                                    readOnly
                                    pointerEvents="none"
                                    background="transparent"
                                    textAlign="center"
                                />
                            </Stack>
                            <Stack spacing={0}>
                                <Text>{`Amount (in USDC):`}</Text>
                                <Input
                                    type="text"
                                    value={amount}
                                    readOnly
                                    pointerEvents="none"
                                    background="transparent"
                                    textAlign="center"
                                />
                            </Stack>
                            {/* 111 */}
                            {isConnected ?
                                <>
                                    {
                                        chain === base && <NormalButton
                                            buttonColor="#FF047E"
                                            onClick={claimTokens}
                                            isDisabled={isClaimDisabled}
                                        >
                                            {`Confirm Claim`}
                                        </NormalButton>
                                    }
                                </>
                                :
                                <ConnectButton w={'full'} />
                            }
                            {isConnected && isEligible && chain !== base && (
                                <>
                                    <SwitchChainButton />
                                    <Text color="red.500" textAlign="center">
                                        {`Please switch to the Base chain for Airdrop Claiming.`}
                                    </Text>
                                </>
                            )}
                            {isConnected && !isEligible && (
                                <Text color="red.500" textAlign="center">
                                    {`You are not eligible for the airdrop.`}
                                </Text>
                            )}
                            {isClaimed && (
                                <Text color="red.500" textAlign="center">
                                    {`Airdrop already claimed.`}
                                </Text>
                            )}
                            {message && <Text color="red.500" textAlign="center">{message}</Text>}

                            <Text color="gray.500" textAlign="center" fontSize="sm">
                                {`USDC Rewards are paid out on the Base chain, `}
                                <SwitchChainButton text="Add Base" btnStyling={false} />
                            </Text>
                        </Stack>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button onClick={onClose}>{`Close`}</Button> */}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default InfoModal;
