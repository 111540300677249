import { Heading, Stack } from "@chakra-ui/react";
import AbstractBanner from '../../assets/images/abstract-banner.svg';
import { useEffect, useState } from "react";
import { NavButtonType } from "../PageWrapper";
import { ActionButton } from "../Buttons/ActionButton";

export default function RewardBanner() {
    const [active, setActive] = useState<NavButtonType>('dashboard');

    useEffect(() => {
        setActive(window.location.pathname.replace('/', '') === 'leaderboard' ? 'leaderboard' : 'dashboard');
    }, []);

    return (
        <Stack
            w={'full'}
            bgSize={'cover'}
            bgRepeat={'no-repeat'}
            bgPosition={'center'}
            backgroundImage={AbstractBanner}
            height={{ base: '220px', sm: '220px', md: '300px', lg: '450px' }}
            justify={'center'}
            align={'center'}
            borderRadius={'20px'}
            p={{ base: '0.2rem', sm: '0.2rem' }}
        >
            <Heading fontFamily={'Space Grotesk'} textAlign={'center'} fontWeight={'700'} fontSize={{ base: '25px', sm: '30px', md: '40px', lg: '40px', xl: '50px' }}>Zayn Testnet Campaign (Airdrop I)</Heading>
            <Heading fontFamily={'Space Grotesk'} textAlign={'center'} fontWeight={'600'} fontSize={{ base: '15px', sm: '20px', md: '20px', lg: '20px', xl: '30px' }}>$5000 in USDT</Heading>
            <Heading fontFamily={'Space Grotesk'} textAlign={'center'} fontWeight={'600'} fontSize={{ base: '15px', sm: '20px', md: '20px', lg: '20px', xl: '30px' }}>100,000 $ZAYN (est. $100K at Target Launch Price)</Heading>

            <Stack w={'full'} spacing={'1.5rem'} direction={'row'} justify={'center'} align={'center'} mt={8}>
                <ActionButton active={active} setActive={setActive} type={'dashboard'}>Dashboard</ActionButton>
                <ActionButton active={active} setActive={setActive} type={'leaderboard'}>Leaderboard</ActionButton>
            </Stack>
        </Stack>
    )
}