import axios from "axios";
import { API_HOST } from "../config";

const MESSAGE = `Click "Sign" to sign in. No password needed!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nI accept the Zayn Testnet API Terms of Service: https://zayn.network/tos\n\naddress: {ADDRESS}`;

export function GenerateSignatureMessage(address: string): string {
    return MESSAGE.replaceAll('{ADDRESS}', address);
}

export async function Authenticate(address: `0x${string}`, signature: `0x${string}`, referral?: string) {
    const body = {
        address,
        message: GenerateSignatureMessage(address),
        signature
    };
    const { data } = await axios.post(`${API_HOST}/api/auth/authenticate${referral ? `?referral=${referral}`: ''}`, body);

    if (data.status === 'success') {
        const response = { token: data.data.token, address, signature, exp: Date.now() + 86400000 };
        localStorage.setItem(
            'signin_session',
            JSON.stringify(response)
        );
        return true;
    } else {
        return false;
    }
}
