import { ReactNode } from "react";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube } from 'react-icons/fa';
import { AiFillMediumCircle } from "react-icons/ai";
import { BsTwitterX } from "react-icons/bs";
import { Box, Container, Link, SimpleGrid, Stack, Text, VisuallyHidden, chakra } from '@chakra-ui/react';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';
import { FaTelegram } from "react-icons/fa6";

export default function Footer() {
    return (
        <Stack w={'full'} px={{ base: 6, sm: 8, md: 12, lg: 14 }} mb={12} mt={8} fontFamily={'Space Grotesk'}>
            <Box
                color={'white'}>
                <Container as={Stack} maxW={'6xl'} py={10}>
                    <SimpleGrid
                        templateColumns={{ sm: '1fr 1fr', md: '2fr 1fr 1fr 1fr 1fr' }}
                        spacing={8}>
                        <Stack spacing={6}>
                            <Box>
                                <Logo />
                            </Box>
                            <Text fontSize={'sm'} opacity={0.65}>
                                The Modular ZK L2 uniting Bitcoin & Ethereum Unlocking Bitcoin liquidity through EVM dApps for the next billion users.
                            </Text>
                        </Stack>
                        <Stack align={'flex-start'}>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Airdrop</ListHeader>
                            <Link href={'/'} opacity={0.65}>Airdrop Testnet</Link>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Testnet</ListHeader>
                            <Link href={'https://explorer.testnet.zayn.network/'} opacity={0.65}>Explorer</Link>
                            <Link href={'https://bridge.testnet.zayn.network/'} opacity={0.65}>Bridge</Link>
                            <Link href={'https://zayn-network.gitbook.io/overview'} opacity={0.65}>Docs</Link>
                            <Link href={'https://zayn-op-testnet.betteruptime.com/'} opacity={0.65}>Status</Link>
                        </Stack>
                        <Stack align={'flex-start'}>
                            <ListHeader>Community</ListHeader>
                            <Link href={'https://x.com/zaynnetwork'} opacity={0.65}>Twitter (X)</Link>
                            <Link href={'https://discord.com/invite/zaynnetwork'} opacity={0.65}>Discord</Link>
                            <Link href={'https://t.me/zaynnetwork'} opacity={0.65}>Telegram</Link>
                            <Link href={'https://medium.com/@ZaynNetwork'} opacity={0.65}>Blogs</Link>
                        </Stack>
                    </SimpleGrid>
                </Container>
            </Box>
            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={'gray.700'}>
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'space-between' }}
                    align={{ base: 'center', md: 'center' }}>
                    <Text textAlign={{ base: 'center', md: 'center' }}  opacity={0.65}>© {new Date().getFullYear()} Copyright, All Right Reserved, Zayn Network</Text>
                    <Stack direction={'row'} spacing={2}>
                        <SocialButton label={'Twitter (X)'} href={'https://x.com/zaynnetwork'}>
                            <BsTwitterX />
                        </SocialButton>
                        <SocialButton label={'Medium'} href={'https://medium.com/@ZaynNetwork'}>
                            <AiFillMediumCircle />
                        </SocialButton>
                        <SocialButton label={'Telegram'} href={'https://t.me/zaynnetwork'}>
                            <FaTelegram />
                        </SocialButton>
                        <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/company/zaynnetwork/'}>
                            <FaLinkedin />
                        </SocialButton>
                    </Stack>
                </Container>
            </Box>
        </Stack>
    )
}

const ListHeader = ({ children }: { children: ReactNode }) => {
    return (
        <Text fontWeight={'500'} fontSize={'lg'} mb={2}>
            {children}
        </Text>
    );
};

const SocialButton = ({
    children,
    label,
    href,
}: {
    children: ReactNode;
    label: string;
    href: string;
}) => {
    return (
        <chakra.button
            bg={'blackAlpha.100'}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            target="_blank"
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: 'blackAlpha.200',
            }}>
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};