import { Heading, Icon, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLink } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function Faucet() {
    const toast = useToast();
    const { user } = useContext(AuthContext);

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Use Faucet to get Ethereum(Zayn L2) Tokens</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are invited to utilize the faucet feature to acquire Ethereum (Zayn L2), our community's digital currency. By utilizing the faucet, users can obtain Ethereum (Zayn L2) tokens, which are essential for completing the task successfully. Each completion of the task earns participants 10 🪙, which contribute to their engagement and rewards within our community. Thank you for participating and contributing to the growth and vibrancy of our network!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                <Stack direction={'row'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                    <Text fontWeight={700} textAlign={'start'}>Faucet:</Text>
                    <Text as={Link} href={'https://zaynswap.com/faucet?utm_medium=airdrop'} target={'_blank'} bg={'black'} borderRadius={'15px'} p={4} cursor={'pointer'}>https://www.zaynswap.com/faucet</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}