import { Button, Collapse, Flex, Icon, IconButton, Image, Link, Stack, Text, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import Logo from '../../assets/images/logo.svg';
import NormalButton from "../Buttons/NormalButton";
import { useEffect, useRef, useState } from "react";
import { CloseIcon, HamburgerIcon, LinkIcon } from "@chakra-ui/icons";
import ConnectButton from "../Buttons/ConnectButton";
import { darken } from "../../utils/colors";
import InfoModal from "../Modal/InfoModal"; // Import the modal component

export default function Navbar() {
    const isMobile = useBreakpointValue({ base: true, sm: true, md: true, lg: false });
    const [showBackground, setShowBackground] = useState(false);
    const [isSubNavOpen, SetSubNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (isSubNavOpen) {
                setShowBackground(true)
            } else {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                setShowBackground(scrollTop > 0); // Change 0 to adjust when the background should appear    
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [isSubNavOpen]);

    function subNavOpen(isOpen: boolean) {
        if (isOpen && !showBackground) {
            setShowBackground(true);
            SetSubNavOpen(isOpen);
            return;
        }

        if (!isOpen) {
            setShowBackground(false);
        }
        SetSubNavOpen(isOpen);
    }

    return (
        <Stack
            w={'full'}
            p={showBackground ? '1rem' : '2rem'}
            zIndex="9999"
            position={'fixed'}
            transition="all 0.3s ease-in-out"
            bg={showBackground ? "rgba(0, 0, 0, 0.8)" : "transparent"}
            px={{ base: '1rem', sm: '1rem', md: '3rem', lg: '3.5rem' }}
        >
            {
                isMobile ? (
                    <MobileNav showBg={subNavOpen} />
                )
                    :
                    (
                        <DesktopNav />
                    )
            }
        </Stack>
    )
}

function DesktopNav() {
    return (
        <Stack
            w={'full'}
            align={'center'}
            direction={'row'}
            justify={'space-between'}
        >
            <Stack as={Link} href={'/'}>
                <Image src={Logo} />
            </Stack>
            <Stack direction={'row'} position={'relative'} align={'center'} spacing={'1.5rem'} color={'white'}>
                {/* add modal button here */}
                <Link href="https://discord.com/invite/zaynnetwork" target="_blank">Community</Link>
                <Link href="https://zayn-network.gitbook.io/overview" target="_blank">Developers</Link>
                <NormalButton buttonColor="#313743">
                    <Link href="https://zaynswap.com/faucet?utm_medium=airdrop" target="_blank">Faucet</Link>
                </NormalButton>
                <InfoModal /> {/* Add the modal button here */}
                <ConnectButton />
            </Stack>
        </Stack>
    )
}

function MobileNav({ showBg }: { showBg: (isOpen: boolean) => void }) {
    const { isOpen, onToggle, onClose } = useDisclosure();
    const ref: any = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event: any) => {
            if (ref.current && !ref.current?.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        }

        showBg(isOpen);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen, onClose]);

    return (
        <Stack w={'full'} ref={ref}>
            <Stack
                w={'full'}
                align={'center'}
                direction={'row'}
                justify={'space-between'}
            >
                <Stack as={Link} href={'/'}>
                    <Image src={Logo} transform={'scale(0.85)'} />
                </Stack>
                <Stack>
                    <IconButton
                        onClick={onToggle}
                        icon={
                            isOpen ? <CloseIcon w={5} h={5} /> : <HamburgerIcon w={5} h={5} />
                        }
                        variant={'ghost'}
                        aria-label={'Toggle Navigation'}
                    />
                </Stack>
            </Stack>
            <Collapse in={isOpen} animateOpacity>
                <CollapseNav />
            </Collapse>
        </Stack>
    )
}

function CollapseNav() {
    return (
        <Stack w={'full'} direction={'column'} justify={'space-between'} mt={4} spacing={'1.5rem'} color={'white'}>
            <LinkItem href="https://discord.com/invite/zaynnetwork">Community</LinkItem>
            <LinkItem href="https://zayn-network.gitbook.io/overview">Developers</LinkItem>
            <Button
                color={'white'}
                as={Link}
                href="https://zaynswap.com/faucet?utm_medium=airdrop"
                background={"#313743"}
                borderColor={'transparent'}
                borderRadius={'5px'}
                transition={'all 0.2s ease-in'}
                p={{
                    base: '4.96px 10.91px 4.96px 10.91px',
                    sm: '14.96px 29.91px 14.96px 29.91px',
                    md: '14.96px 29.91px 14.96px 29.91px',
                    lg: '14.96px 29.91px 14.96px 29.91px'
                }}
                _hover={{
                    background: darken("#313743", 5),
                }}
                _active={{
                    background: darken("#313743", 5 * 2)
                }}
                cursor={'pointer'}
            >
                Faucet
            </Button>
            <InfoModal /> {/* Add the modal button here */}
            <ConnectButton w={'full'} />
        </Stack>
    )
}

function LinkItem({ children, href }: any) {
    return (
        <Flex
            py={2}
            as={Link}
            target="_blank"
            href={href || '#'}
            justify={'space-between'}
            align={'center'}
            _hover={{
                textDecoration: 'none',
            }}>
            <Text
                fontWeight={600}
                color={'white'}>
                {children}
            </Text>
            <Icon
                as={LinkIcon}
                transition={'all .25s ease-in-out'}
                w={3}
                h={3}
            />
        </Flex>
    );
}