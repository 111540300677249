import './App.css';
import Web3ModalProvider from './_providers/context'
import { Alert, AlertDescription, AlertTitle, ChakraProvider, Link, extendTheme } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import PageWrapper from './components/PageWrapper';
import Footer from './components/Footer';
import AuthProvider from './components/context/AuthProvider';
import { InfoIcon } from '@chakra-ui/icons';

const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: {
    global: {
      body: {
        bg: '#10141C',
        fontFamily: 'Space Grotesk'
      }
    }
  }
})
function App() {
  return (
    <AuthProvider>
      <ChakraProvider theme={theme}>
        <Web3ModalProvider>
          <Navbar />
          <PageWrapper />
          <Footer />
          <Alert status='info' gap={2} bg={'#FF047E'} alignItems={'center'} justifyContent={'center'} position={'fixed'} bottom={'0px'}>
            <InfoIcon />
            <AlertTitle>Airdrop II is coming soon 🥳</AlertTitle>
            <AlertDescription>Follow us on <Link href='https://x.com/ZaynNetwork' target={'_blank'} fontWeight={'bold'}>Twitter</Link> and <Link href='https://discord.com/invite/zaynnetwork' target={'_blank'} fontWeight={'bold'}>Join our Discord Community</Link> for more information.</AlertDescription>
          </Alert>
        </Web3ModalProvider>
      </ChakraProvider>
    </AuthProvider>
  );
}

export default App;
