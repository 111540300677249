import React, { useState, useEffect } from 'react';
import AuthContext, { UserType } from './AuthContext';
import axios from 'axios';
import { API_HOST } from '../../config';

const AuthProvider = ({ children }: { children: any }) => {
    const [user, setUser] = useState<UserType>();
    const [isLoading, setLoading] = useState<boolean>(true);

    async function refresh(): Promise<boolean> {
        setLoading(true);
        const authString = localStorage.getItem('signin_session');
        if (!authString || authString === "" || authString === "{}") {
            setLoading(false);
            return false;
        };

        const auth: any = JSON.parse(authString);
        if (!auth.exp || !auth.token || !auth.signature || !auth.address) {
            localStorage.removeItem('signin_session');
            window.location.reload();
            return false;
        }

        try {
            const { data } = await axios.get(`${API_HOST}/api/auth/me?token=${auth.token}`);
            if (data.status !== 'success') throw new Error('Error in Authentication!');

            setUser({
                ...data.data,
                token: auth.token,
                exp: auth.exp,
                signature: auth.signature,
            });
            setLoading(false);
            return true;
        } catch (error) {
            localStorage.removeItem('signin_session');
            window.location.reload();
            return false;
        }
    }

    useEffect(() => {
        refresh();
    }, []);

    return (
        <AuthContext.Provider value={{ isLoading, isAuthenticated: !!user, user, refresh }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
