export async function CopyToClipboard(text: string): Promise<boolean> {
  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (error) {
    console.error('Failed to copy: ', error);
    return false;
  }
}

export function FormatNumber(num: number) {
  if (num >= 1000000000000) {  // Trillion
    return (num / 1000000000000).toFixed(2) + 'T';
  } else if (num >= 1000000000) {  // Billion
    return (num / 1000000000).toFixed(2) + 'B';
  } else if (num >= 1000000) {  // Million
    return (num / 1000000).toFixed(2) + 'M';
  } else if (num >= 1000) {  // Thousand
    return (num / 1000).toFixed(2) + 'K';
  } else {
    return num.toString();  // Less than 1000
  }
}

export function FormatNumberCommaSeperated(num: number) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}