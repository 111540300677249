import { Button, ButtonProps, ResponsiveValue, ThemeTypings } from "@chakra-ui/react";
import { darken } from "../../utils/colors";

interface NormalButtonProps extends ButtonProps {
    buttonColor: string,
    intensity?: number;
}

export default function NormalButton(props: NormalButtonProps) {
    return (
        <Button
            color={'white'}
            background={props.buttonColor}
            borderColor={'transparent'}
            borderRadius={'5px'}
            transition={'all 0.2s ease-in'}
            p={{
                base: '4.96px 10.91px 4.96px 10.91px',
                sm: '14.96px 29.91px 14.96px 29.91px',
                md: '14.96px 29.91px 14.96px 29.91px',
                lg: '14.96px 29.91px 14.96px 29.91px'
            }}
            _hover={{
                background: darken(props.buttonColor, props.intensity || 5),
            }}
            _active={{
                background: darken(props.buttonColor, (props.intensity || 5) * 2)
            }}
            _disabled={{
                background: 'gray.400',
                cursor: 'not-allowed',
                color: 'gray.700',
            }}
            cursor={'pointer'}
            {...props}
        >
            {props.children}
        </Button>
    )
}