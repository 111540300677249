import { Box, Icon, Stack, Text } from "@chakra-ui/react";
import { ReactComponent as PlayIconSVG } from "../../assets/icons/PlayIcon.svg";
import { brighten } from "../../utils/colors";
import { Link } from "react-router-dom";

interface TaskBannerType {
    text: string,
    points: number,
    to: string
}
export default function TaskBanner({ text, points, to }: TaskBannerType) {
    return (
        <Stack
            as={Link}
            to={to}
            py={4}
            px={{ base: 2, sm: 4, md: 8 }}
            mx={4}
            spacing={{ base: 4, md: 6 }}
            bg={'#000'}
            align={'center'}
            direction={'row'}
            borderRadius={'10px'}
            transition={'all 0.3s ease-in-out'}
            _hover={{
                boxShadow: '0px 0px 6px 1px #9f939338',
                cursor: 'pointer'
            }}
            _active={{
                boxShadow: '0px 0px 20px 1px #9f939352',
            }}
        >
            <Stack
            >
                <Icon as={PlayIconSVG} />
            </Stack>
            <Stack
                w={'full'}
                direction={'row'}
                justify={'space-between'}
                align={'center'}
            >
                <Text w={'80%'}>{text}</Text>
                <Text>{points} 🪙</Text>
            </Stack>
        </Stack>
    )
}