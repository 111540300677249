import { FaXTwitter } from "react-icons/fa6";
import { darken } from "../../utils/colors";
import NormalButton from "./NormalButton";

export default function XButton({ text, onClick, isLoading }: { text: string, isLoading?: boolean, onClick?: () => void }) {
    return (
        <NormalButton
            isLoading={isLoading}
            buttonColor={'#10141C'}
            _hover={{ bg: '#FF047E' }}
            _active={{ bg: darken('#FF047E', 20) }}
            size={'lg'}
            w={'fit-content'}
            px={8}
            rightIcon={<FaXTwitter />}
            onClick={() => onClick && onClick()}
        >
            {text}
        </NormalButton>

    )
}