import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { mainnet, polygon, optimism, avalanche, bsc, goerli, sepolia, moonbeam, base } from 'wagmi/chains'

export const projectId = 'aa071b020fa54729d2d0ac18937f9f4f'
if (!projectId) throw new Error('Project ID is not defined')

const RPC = 'https://zayn-op-testnet.rpc.caldera.xyz/http'
const ZaynNetwork = {
    id: 9297,
    name: 'Zayn Network',
    nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
    },
    rpcUrls: {
        default: { http: [RPC] },
        public: { http: [RPC] },
    },
    testnet: true,
}

const metadata = {
    name: 'Zayn Testnet Campaign (Airdrop I)',
    description: 'This is Zayn Testnet Campaign (Airdrop I)',
    url: 'https://zayn.network/',
    icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [ZaynNetwork, mainnet, polygon, optimism, avalanche, bsc, goerli, sepolia, moonbeam, base] as const
export const config = defaultWagmiConfig({
    chains,
    projectId,
    metadata,
    ssr: true,
})