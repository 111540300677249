import { useEffect } from "react";
import { Tasks } from "../utils/task";
import { Link, Stack, useToast } from "@chakra-ui/react";
import { Link as ReactLink, useNavigate, useParams } from "react-router-dom";
import Follow from "../components/Tasks/Follow";
import Retweet from "../components/Tasks/Retweet";
import Refer from "../components/Tasks/Refer";
import Swap from "../components/Tasks/Swap";
import Bridge from "../components/Tasks/Bridge";
import AddLiquidity from "../components/Tasks/AddLiquidty";
import Farm from "../components/Tasks/Farm";
import Faucet from "../components/Tasks/Faucet";

export function TaskDetail() {
    const toast = useToast();
    const navigate = useNavigate();
    const { task } = useParams<{ task: string }>();

    useEffect(() => {
        if (!Object.values(Tasks).includes(task as Tasks)) return navigate('/not-found')
    }, [task]);

    return (
        <Stack w={'full'} spacing={6}>
            <Link
                px={4}
                to={'/'}
                as={ReactLink}
                color={'white'}
                fontWeight={200}
                fontSize={'14px'}
                textDecoration={'none'}
            >
                {'<'} Back to tasks
            </Link>
            <Task task={task as Tasks} />
        </Stack>
    )
}

function Task({ task }: { task: Tasks }) {
    switch (task) {
        case Tasks.FOLLOW: {
            return <Follow />;
        }
        case Tasks.RETWEET: {
            return <Retweet />;
        }
        case Tasks.REFER: {
            return <Refer />;
        }
        case Tasks.SWAP: {
            return <Swap />;
        }
        case Tasks.BRDIGE: {
            return <Bridge />;
        }
        case Tasks.ADDLIQUIDITY: {
            return <AddLiquidity />;
        }
        case Tasks.FARM: {
            return <Farm />;
        }
        case Tasks.FAUCET: {
            return <Faucet />;
        }
    }
}