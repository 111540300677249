import { Heading, Icon, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLink } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function Farm() {
    const toast = useToast();
    const { user } = useContext(AuthContext);

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Farm your LP Token on Zayn Dex</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are encouraged to maximize their involvement with the Zayn Decentralized Exchange (DEX) by farming their Liquidity Provider (LP) tokens. By staking LP tokens in the farming pools, users can earn rewards while contributing to the liquidity of the exchange. Each successful farming of LP tokens on the Zayn DEX earns participants 40 🪙, recognizing their active participation in the decentralized finance ecosystem and their support in enhancing the liquidity and efficiency of our platform. Thank you for your dedication and contribution to our community's growth and success!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                <Stack direction={'row'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                    <Text fontWeight={700} textAlign={'start'}>Add Liquidty:</Text>
                    <Text as={Link} href={'https://www.zaynswap.com/farm'} target={'_blank'} bg={'black'} borderRadius={'15px'} p={4} cursor={'pointer'}>https://www.zaynswap.com/farm</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}