import { useNavigate } from "react-router-dom";
import { NavButtonType } from "../PageWrapper";
import NormalButton from "./NormalButton";

export function ActionButton({ active, setActive, type, children }: { active: NavButtonType, setActive: (active: NavButtonType) => void, type: NavButtonType, children: any }) {
    const navigate = useNavigate();

    function handleNavButtonClick(action: NavButtonType) {
        setActive(action);
        navigate(`${action === 'dashboard' ? '/' : 'leaderboard'}`);
    }

    return (
        <NormalButton
            buttonColor={active === type ? '#FF047E' : '#313743'}
            onClick={() => handleNavButtonClick(type)}
        >
            {children}
        </NormalButton>
    )
}