import { Heading, Icon, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLink } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function Swap() {
    const toast = useToast();
    const { user } = useContext(AuthContext);

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Do a Swap on Zayn Dex</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are encouraged to engage with the Zayn Decentralized Exchange (DEX) by performing a token swap. By utilizing the Zayn DEX, users can securely exchange their digital assets, facilitating liquidity provision and market participation. Each successful token swap completed on the Zayn DEX earns participants 40 🪙, acknowledging their active involvement in the decentralized finance ecosystem and supporting the growth of our network. Thank you for your participation and commitment to advancing decentralized exchange technologies!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                <Stack direction={'row'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                    <Text fontWeight={700} textAlign={'start'}>Swap:</Text>
                    <Text as={Link} href={'https://www.zaynswap.com/en/swap'} target={'_blank'} bg={'black'} borderRadius={'15px'} p={4} cursor={'pointer'}>https://www.zaynswap.com/en/swap</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}