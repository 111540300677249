import { useSwitchChain } from 'wagmi';
import { base } from 'wagmi/chains';
import NormalButton from "./NormalButton";

type props = {
    text?: string
    btnStyling?: boolean
}
const SwitchChainButton = ({ text = "Switch to Base Chain", btnStyling = true }: props) => {
    const { switchChain } = useSwitchChain();

    const handleSwitch = () => {
        switchChain({ chainId: base.id });
    };

    return <>
        {
            btnStyling ?
                <NormalButton buttonColor="#FF047E" onClick={handleSwitch}>
                    {text}
                </NormalButton > :
                <span onClick={handleSwitch} style={{ textDecoration: "underline", cursor: "pointer" }}>
                    {text}
                </span >
        }
    </>
};

export default SwitchChainButton;