import { Heading, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import Logo from '../../assets/images/logo.svg';
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLogoFacebook, BiLogoTelegram, BiLogoTwitter, BiLogoWhatsapp } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function Refer() {
    const toast = useToast();
    const { isLoading, isAuthenticated, user } = useContext(AuthContext);

    async function handleCopyButton() {
        const response = await CopyToClipboard(`${window.location.origin}?referral=${user?.referralCode}`);
        if (response) return toast({
            position: 'bottom-right',
            render: () => <SuccessToast text="Copied to clipboard!" />
        });

        toast({
            position: 'bottom-right',
            render: () => <ErrorToast text="Something's Wrong :(" description="Failed to copy text to clipboard" />
        })
    }
    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Refer Zayn to all of your friends</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are encouraged to refer friends and acquaintances to join our community. For each successful referral, participants will be generously rewarded with 40 🪙. Referrals play a vital role in expanding our network, fostering connections, and enriching the overall experience for everyone involved. Thank you for sharing the opportunity and contributing to the growth of our community!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                {
                    (!isLoading && !isAuthenticated) ? (
                        <Text>Connect wallet to get referral code</Text>
                    )
                        :
                        (
                            <Stack direction={'column'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                                <Skeleton isLoaded={user !== undefined} borderRadius={'15px'}>
                                    <Text bg={'black'} borderRadius={'15px'} p={4}>{window.origin}?referral={user?.referralCode || "PROBONO"}</Text>
                                </Skeleton>
                                <Stack direction={'row'}>
                                    <IconButton
                                        isLoading={user === undefined}
                                        as={Link}
                                        href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fairdrop.zayn.network%2F%3Freferral%3D${user?.referralCode || "PROBONO"}&amp;src=sdkpreparse`}
                                        icon={<BiLogoFacebook />}
                                        target={'_blank'}
                                        bg={'black'}
                                        color={'white'}
                                        _hover={{ bg: 'facebook.400' }}
                                        _active={{ bg: 'facebook.600' }}
                                        aria-label="Copy Link"
                                        borderRadius={'15px'}
                                        h={'56px'}
                                        w={'56px'}
                                        fontSize={'30px'}
                                    />
                                    <IconButton
                                        isLoading={user === undefined}
                                        icon={<BiLogoTwitter />}
                                        as={Link}
                                        href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(`Hey, I participated in the Zayn Network L2 airdrop campaign and earned $USDT & $ZAYN! You should participate too and earn 💰. No upfront cash required. Here's my invite link: https://airdrop.zayn.network?referral=${user?.referralCode || "PROBONO"}`)}`}
                                        target={'_blank'}
                                        bg={'black'}
                                        color={'white'}
                                        _hover={{ bg: 'twitter.400' }}
                                        _active={{ bg: 'twitter.600' }}
                                        aria-label="Copy Link"
                                        borderRadius={'15px'}
                                        h={'56px'}
                                        w={'56px'}
                                        fontSize={'30px'}
                                    />
                                    <IconButton
                                        isLoading={user === undefined}
                                        icon={<BiLogoTelegram />}
                                        as={Link}
                                        href={`https://t.me/share/url?url=https://airdrop.zayn.network?referral=${user?.referralCode || "PROBONO"}&text=${encodeURIComponent(`Hey, I participated in the Zayn Network L2 airdrop campaign and earned $USDT & $ZAYN! You should participate too and earn 💰. No upfront cash required. Here's my invite link: https://airdrop.zayn.network?referral=${user?.referralCode || "PROBONO"}`)}`}
                                        target={'_blank'}
                                        bg={'black'}
                                        color={'white'}
                                        _hover={{ bg: 'telegram.400' }}
                                        _active={{ bg: 'telegram.600' }}
                                        aria-label="Copy Link"
                                        borderRadius={'15px'}
                                        h={'56px'}
                                        w={'56px'}
                                        fontSize={'30px'}
                                    />
                                    <IconButton
                                        isLoading={user === undefined}
                                        icon={<BiLogoWhatsapp />}
                                        as={Link}
                                        href={`https://wa.me/?text=${encodeURIComponent(`Hey, I participated in the Zayn Network L2 airdrop campaign and earned $USDT & $ZAYN! You should participate too and earn 💰. No upfront cash required. Here's my invite link: https://airdrop.zayn.network?referral=${user?.referralCode || "PROBONO"}`)}`}
                                        target={'_blank'}
                                        bg={'black'}
                                        color={'white'}
                                        _hover={{ bg: 'whatsapp.400' }}
                                        _active={{ bg: 'whatsapp.600' }}
                                        aria-label="Copy Link"
                                        borderRadius={'15px'}
                                        h={'56px'}
                                        w={'56px'}
                                        fontSize={'30px'}
                                    />
                                    <IconButton
                                        isLoading={user === undefined}
                                        icon={<BiCopy />}
                                        bg={'black'}
                                        color={'white'}
                                        _hover={{ bg: '#FF047E' }}
                                        _active={{ bg: darken('#FF047E', 20) }}
                                        aria-label="Copy Link"
                                        borderRadius={'15px'}
                                        h={'56px'}
                                        w={'56px'}
                                        fontSize={'30px'}
                                        onClick={handleCopyButton}
                                    />
                                </Stack>
                            </Stack>
                        )
                }
            </Stack>
        </Stack>
    )
}