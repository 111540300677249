import { Box, Icon, Stack, Text } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

export interface SuccessToastType {
    text: string,
    description?: string
}
export default function SuccessToast({ text, description }: SuccessToastType) {
    return (
        <Box
            p={4}
            bg={'black'}
            color="white"
            borderRadius={'10px'}
            boxShadow={'0px 0px 10px 0px #00000073'}
        >
            <Stack direction={'row'} spacing={3} align={'center'}>
                <Stack
                    flex={0}
                >
                    <Icon as={IoMdCheckmarkCircleOutline} w={'25px'} h={'25px'} color={'#2a7e1b'} />
                </Stack>
                <Stack
                    flex={1}
                >
                    <Text>{text}</Text>
                </Stack>
            </Stack>
            {description && (
                <Stack direction={'row'} spacing={3} align={'center'}>
                    <Stack
                        flex={0}
                        opacity={0}
                    >
                        <Icon as={IoMdCheckmarkCircleOutline} w={'25px'} h={'25px'} color={'#2a7e1b'} />
                    </Stack>
                    <Stack flex={1} color={'gray.500'} fontSize={'sm'}>
                        <Text>{description}</Text>
                    </Stack>
                </Stack>
            )}
        </Box>
    )
}