import { Skeleton, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Fragment, useContext } from "react";
import AuthContext from "../context/AuthContext";
import { FormatNumberCommaSeperated } from "../../utils/common";

export interface LeaderboardItemProps {
    rank: number,
    points: number,
    address: string
}


export default function LeaderboardTable({ users, loading, you }: { users: LeaderboardItemProps[], loading: boolean, you?: LeaderboardItemProps }) {
    const { user: connectedUser } = useContext(AuthContext);

    return (
        <TableContainer borderRadius={'22.17px'} background={'#313743'}>
            <Table size={{ base: 'sm', sm: 'sm', md: 'md', lg: 'lg' }} overflowY={'scroll'} h={'xl'}>
                <Thead h={'75px'}>
                    <Tr>
                        <Th textAlign={'center'}>Rank</Th>
                        <Th textAlign={'center'}>Points Calculated</Th>
                        <Th textAlign={'center'}>Address</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {
                        loading ?
                            (
                                <Fragment>
                                    <Tr h={'75px'} bg={'#10141C'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                    <Tr h={'75px'} bg={'transparent'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                    <Tr h={'75px'} bg={'#10141C'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                    <Tr h={'75px'} bg={'transparent'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                    <Tr h={'75px'} bg={'#10141C'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                    <Tr h={'75px'} bg={'transparent'}>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>1</Skeleton></Td>
                                        <Td textAlign={'center'}><Skeleton isLoaded={!loading}>123</Skeleton></Td>
                                        <Td textAlign={'center'}>
                                            <Skeleton isLoaded={!loading}>
                                                abc
                                            </Skeleton>
                                        </Td>
                                    </Tr>
                                </Fragment>
                            ) :
                            (
                                <>
                                    {
                                        you ? (
                                            <>
                                                {
                                                    users.findIndex(x => x.address.toLowerCase() === you.address.toLowerCase()) >= 0 ? (
                                                        users.map((user: LeaderboardItemProps, i: number) => (
                                                            <Tr h={'75px'} bg={connectedUser ? (connectedUser.address.toLowerCase() === user.address.toLowerCase() ? '#FF047E' : (((i % 2) === 0) ? '#10141C' : 'transparent')) : (((i % 2) === 0) ? '#10141C' : 'transparent')}>
                                                                <Td textAlign={'center'}>{user.rank}</Td>
                                                                <Td textAlign={'center'}>{FormatNumberCommaSeperated(user.points)}</Td>
                                                                <Td textAlign={'center'}>
                                                                    {user.address.substring(0, 7)}...{user.address.substring(user.address.length - 3)}
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                    )
                                                        :
                                                        (
                                                            <>
                                                                <Tr h={'75px'} bg={'#FF047E'}>
                                                                    <Td textAlign={'center'}>{you.rank}</Td>
                                                                    <Td textAlign={'center'}>{FormatNumberCommaSeperated(you.points)}</Td>
                                                                    <Td textAlign={'center'}>
                                                                        {you.address.substring(0, 7)}...{you.address.substring(you.address.length - 3)}
                                                                    </Td>
                                                                </Tr>
                                                                {
                                                                    users.map((user: LeaderboardItemProps, i: number) => (
                                                                        <Tr h={'75px'} bg={connectedUser ? (connectedUser.address.toLowerCase() === user.address.toLowerCase() ? '#FF047E' : (((i % 2) === 0) ? '#10141C' : 'transparent')) : (((i % 2) === 0) ? '#10141C' : 'transparent')}>
                                                                            <Td textAlign={'center'}>{user.rank}</Td>
                                                                            <Td textAlign={'center'}>{FormatNumberCommaSeperated(user.points)}</Td>
                                                                            <Td textAlign={'center'}>
                                                                                {user.address.substring(0, 7)}...{user.address.substring(user.address.length - 3)}
                                                                            </Td>
                                                                        </Tr>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                }
                                            </>
                                        )
                                            :
                                            (
                                                users.map((user: LeaderboardItemProps, i: number) => (
                                                    <Tr h={'75px'} bg={connectedUser ? (connectedUser.address.toLowerCase() === user.address.toLowerCase() ? '#FF047E' : (((i % 2) === 0) ? '#10141C' : 'transparent')) : (((i % 2) === 0) ? '#10141C' : 'transparent')}>
                                                        <Td textAlign={'center'}>{user.rank}</Td>
                                                        <Td textAlign={'center'}>{FormatNumberCommaSeperated(user.points)}</Td>
                                                        <Td textAlign={'center'}>
                                                            {user.address.substring(0, 7)}...{user.address.substring(user.address.length - 3)}
                                                        </Td>
                                                    </Tr>
                                                ))
                                            )
                                    }
                                </>
                            )
                    }
                </Tbody>
            </Table>
        </TableContainer>
    )
}