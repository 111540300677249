import { Stack } from "@chakra-ui/react";
import RewardBanner from "../Banners/RewardBanner";
import NormalButton from "../Buttons/NormalButton";
import PageRouter from "../../router";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, useLocation, useNavigate } from "react-router-dom";

export type NavButtonType = 'dashboard' | 'leaderboard';

export default function PageWrapper() {    
    return (
        <Router>
            <Stack width={'full'} px={{ base: '1rem', sm: '1rem', md: '3rem', lg: '3.5rem' }} py={4}>
                <Stack px={'0.3rem'} pt={'100px'} pb={'0.3rem'} fontFamily={'Space Grotesk'} spacing={'1.5rem'}>
                    <RewardBanner />
                    <Stack w={'full'}>
                        <PageRouter />
                    </Stack>
                </Stack>
            </Stack>
        </Router>
    )
}