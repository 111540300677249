import { Heading, Stack, StackDivider, Text } from "@chakra-ui/react";
import InfoBanner from "../components/Banners/InfoBanner";
import AvatarGroup from "../components/Avatars/AvatarGroup";
import TaskBanner from "../components/Banners/TaskBanner";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_HOST } from "../config";

export default function Tasks() {
    const [usersCount, setUsersCount] = useState<number>(0);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(`${API_HOST}/api/auth/users/total`);
                if (data.status !== 'success') throw new Error('API Request Failed');
                setUsersCount(data.data);
            } catch (error) {
            }
        })()
    }, []);
    return (
        <Stack w={'full'} spacing={'3rem'}>
            <Stack w={'full'} spacing={'1.5rem'}>
                <InfoBanner
                    no={1}
                    text={'Top 200 wallet addresses will share 50% of the reward pool.'}
                />
                <InfoBanner
                    no={2}
                    text={'The remaining 50% of the reward pool will be allocated by the share of points obtained by all participants. The more points you get, the bigger allocation of the USDT & $ZAYN you will receive.'}
                />
                <InfoBanner
                    no={3}
                    text={'USDT reward pool will be distributed by 20th June 2024.'}
                />
                <InfoBanner
                    no={4}
                    text={'$ZAYN reward pool will be distributed at TGE est. in August 2024.'}
                />
            </Stack>
            <Stack w={'full'} spacing={'2rem'} px={6}>
                <Heading fontFamily={'Space Grotesk'} fontWeight={500} fontSize={'2xl'}>Earn points by doing simple tasks</Heading>
                <Stack direction={'row'} w={'full'} justify={'flex-start'} align={'center'} spacing={'1.5rem'} divider={<StackDivider color={'#fff'} />}>
                    <AvatarGroup
                        size="sm"
                        gap="-25px"
                        profileLinks={[
                            'https://effigy.im/a/0x972735eD0eC6083a331dbAEC7403769FFd866D1a.svg',
                            'https://effigy.im/a/0x0Ae2bd15d4834834DBCbd73535db5ed008314fb2.svg',
                            'https://effigy.im/a/0xf5f9899c41Ce32b034ac095E7f225E6203F0Ce17.svg',
                            'https://effigy.im/a/0x27461444FbD9d6a11096221a83925d0645E67aFE.svg'
                        ]}
                        count={usersCount}
                    />
                    <Text>2024/06/03 7:00 A.M. UTC - 2024/06/17 7:00 A.M. UTC</Text>
                </Stack>
            </Stack>
            <Stack w={'full'} spacing={'1.5rem'}>
                <TaskBanner
                    text={'Follow Zayn Network on X.com'}
                    points={40}
                    to={'/task/follow'}
                />
                <TaskBanner
                    text={'Retweet Zayn Network\'s Announcement Tweet.'}
                    points={20}
                    to={'/task/retweet'}
                />
                <TaskBanner
                    text={'Use Faucet to get Ethereum (Zayn L2) Tokens.'}
                    points={10}
                    to={'/task/faucet'}
                />
                <TaskBanner
                    text={'Bridge Ethereum (Sepolia) Token to Zayn L2.'}
                    points={20}
                    to={'/task/bridge'}
                />
                <TaskBanner
                    text={'Do a Swap on Zayn Dex.'}
                    points={40}
                    to={'/task/swap'}
                />
                <TaskBanner
                    text={'Add Liquidity on Zayn Dex Pools.'}
                    points={40}
                    to={'/task/add-liquidity'}
                />
                <TaskBanner
                    text={'Farm your LP Token on Zayn Dex.'}
                    points={40}
                    to={'/task/farm'}
                />
                <TaskBanner
                    text={'Refer your friend.'}
                    points={40}
                    to={'/task/refer'}
                />
            </Stack>
        </Stack>
    )
}