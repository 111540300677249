import Leaderboard from "../pages/Leaderboard";
import { TaskDetail } from "../pages/TaskDetail";
import Tasks from "../pages/Tasks";

export interface RouteProps {
    url: string;
    element: JSX.Element
}

const PageRoutes: RouteProps[] = [
    { url: '/', element: <Tasks /> },
    { url: '/leaderboard', element: <Leaderboard /> },
    { url: '/task/:task', element: <TaskDetail /> },
]

export default PageRoutes;