import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useContext, useEffect, useState } from 'react';
import NormalButton from './NormalButton';
import { ButtonProps, useToast } from '@chakra-ui/react';
import WagmiAvatar from '../Avatars/WagmiAvatar';
import ErrorToast from '../Toasts/Error';
import { useAccount, useAccountEffect, useDisconnect, useSignMessage } from 'wagmi';
import SuccessToast from '../Toasts/Success';
import { Authenticate, GenerateSignatureMessage } from '../../utils/auth';
import AuthContext from '../context/AuthContext';

function isSignatureType(value: any): value is `0x${string}` {
    return typeof value === 'string' && value.startsWith('0x');
}

export default function ConnectButton(props: ButtonProps) {
    const { user: auth, refresh } = useContext(AuthContext);
    const toast = useToast();
    const { open, close } = useWeb3Modal()
    const { disconnect } = useDisconnect();
    const { address, isConnected } = useAccount();
    const { data: signature, error, signMessage } = useSignMessage();
    const [referral, setReferral] = useState<string>();


    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const referCode = query.get('referral');
        if (referCode && referCode !== '') {
            setReferral(referCode);
        }
    }, []);

    useAccountEffect({
        onConnect(data) {
            const auth = JSON.parse(localStorage.getItem('signin_session') || "{}");
            if (!isSignatureType(auth?.signature)) {
                signMessage({ message: GenerateSignatureMessage(data.address) });
            } else {
                refresh();
            }
        },
        onDisconnect() {
            localStorage.removeItem('signin_session')
            window.location.reload();
        },
    })

    useEffect(() => {
        if (error) {
            toast({
                position: 'bottom-right',
                render: () => <ErrorToast text='Request Rejected :(' description='Please sign the message to continue' />,
                duration: 4000
            });
            close();
            disconnect();
            localStorage.removeItem('signin_session')
        }
    }, [error]);

    useEffect(() => {
        if (signature && address) {
            (async () => {
                try {
                    const signedIn = await Authenticate(address, signature, referral);
                    if (!signedIn) throw new Error('Login unsuccessful!');
                    toast({
                        position: 'bottom-right',
                        render: () => <SuccessToast text='Wallet Address Authenticated Successfully!' />,
                        duration: 4000
                    })
                    refresh();
                } catch (error) {
                    toast({
                        position: 'bottom-right',
                        render: () => <ErrorToast text='Something went wrong :(' description='Please try again later in a few minutes.' />,
                        duration: 4000
                    });
                }
            })()
        }
    }, [signature, auth?.address]);

    return (
        isConnected && address ?
            (
                <WagmiAvatar
                    address={address}
                    onClick={() => open()}
                />
            )
            :
            (
                <NormalButton buttonColor="#FF047E" intensity={10} {...props
                } onClick={() => open()
                }>
                    {`Connect Wallet`}
                </NormalButton>
            )
    )
}