import React from 'react';

export interface UserType {
    address: string,
    twitter_id: string,
    username: string,
    name: string,
    referralCode: string,
    points: number,
    token: string,
    signature: string
}

export interface AppContext {
    isLoading: boolean,
    isAuthenticated: boolean,
    user?: UserType,
    refresh: () => Promise<boolean>
}
const AuthContext = React.createContext<AppContext>({
    isLoading: false,
    isAuthenticated: false,
    user: undefined,
    refresh: async() => true
});

export default AuthContext;
