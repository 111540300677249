import { Avatar, Stack, Text } from "@chakra-ui/react";
import { FormatNumber } from "../../utils/common";

type Size = 'sm' | 'md' | 'lg' | 'xl' | '2xl';
export default function AvatarGroup({ profileLinks, size, gap, count }: { profileLinks: string[], size: Size, gap: string, count: number }) {
    return (
        <Stack direction={'row'} align={'center'}>
            {
                profileLinks.map((x, i) => (
                    <Avatar size={size} name={x} marginLeft={i === 0 ? undefined : gap} border={'2px solid white'} src={x} />
                ))
            }
            <Text>{FormatNumber(count)}+</Text>
        </Stack>
    )
}