import { Heading, Icon, IconButton, Image, Link, Skeleton, Stack, Text, useToast } from "@chakra-ui/react";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import Logo from '../../assets/images/logo.svg';
import XButton from "../Buttons/XButton";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../context/AuthContext";
import { BiCopy, BiLink } from "react-icons/bi";
import { darken } from "../../utils/colors";
import { CopyToClipboard } from "../../utils/common";
import SuccessToast from "../Toasts/Success";
import ErrorToast from "../Toasts/Error";

export default function Bridge() {
    const toast = useToast();
    const { user } = useContext(AuthContext);

    return (
        <Stack w={'full'} bg={'black'} p={8} borderRadius={'20px'} spacing={6}>
            <Heading fontFamily={'Space Grotesk'}>Bridge Ethereum(Sepolia) to Zayn L2 Tokens</Heading>
            <Stack direction={'row'} spacing={4} align={'center'}>
                <Heading fontSize={'lg'} fontFamily={'Space Grotesk'}>Curated by</Heading>
                <Image src={Logo} w={'100px'} />
            </Stack>

            <Stack w={'full'} spacing={0}>
                <Text fontWeight={700}>Description:</Text>
                <Text>Participants are invited to utilize the bridge functionality to seamlessly transfer Ethereum tokens from the Sepolia network to Zayn Layer 2 (L2) tokens. By leveraging the bridge, users can securely migrate their assets between different blockchain networks, enhancing their accessibility and utility across platforms. Each successful transaction through the bridge earns participants 20 🪙, acknowledging their role in fostering interoperability and expanding the reach of our network. Thank you for actively participating and contributing to the growth of our community!</Text>
            </Stack>

            <Stack w={'full'} justify={'center'} align={'center'}>
                <Stack direction={'row'} align={'center'} p={3} bg={'#10141C'} borderRadius={'15px'}>
                    <Text fontWeight={700} textAlign={'start'}>Bridge:</Text>
                    <Text as={Link} href={'https://bridge.testnet.zayn.network/'} target={'_blank'} bg={'black'} borderRadius={'15px'} p={4} cursor={'pointer'}>https://bridge.testnet.zayn.network/</Text>
                </Stack>
            </Stack>
        </Stack>
    )
}