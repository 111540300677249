import { Box, Stack, Text } from "@chakra-ui/react";

interface InfoBannerType {
    no: number,
    text: string
}
export default function InfoBanner({ no, text }: InfoBannerType) {
    return (
        <Stack direction={'row'} py={4} px={8} mx={4} spacing={6} bg={'#000'} borderRadius={'10px'} align={'center'}>
            <Stack
                flex={0}
            >
                <Text w={8} h={8} background={'#FF047E'} borderRadius={'100%'} textAlign={'center'} pt={1} pr={0.4} align={'center'}>{no}</Text>
            </Stack>
            <Stack
                flex={2}
            >
                <Text>{text}</Text>
            </Stack>
        </Stack>
    )
}